<template>
  <div class="team">
    <img src="~img/35.png" class="bg-img">
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul>
        <li v-for="item in teamList" :key="item.id">
          <div class="top">
            <img src="~img/57.png">
            <p class="p1">{{item.name}}</p>
          </div>
          <p class="p2">公益活动数量：{{item.activity_count}}次</p>
          <p class="p2">成员：{{item.user_count}}人</p>
          <div class="btn-box">
            <button :class="{join:item.has_join === 1}"
              @click="isJoin(item)">{{item.has_join=== 0 ? '加入团队':'退出团队'}}</button>
            <button @click="toActivity(item.name)">团队活动</button>
          </div>
        </li>
      </ul>
    </van-list>
    <tabbar />
  </div>
</template>


<script>
import tabbar from "../components/Tabbar.vue";
import wxShare from "../utils/wxShare.js";

export default {
  components: {
    tabbar,
  },
  data() {
    return {
      teamList: [],
      limit: 5,
      page: 0,
      loading: false,
      finished: false,
    };
  },
  created() {},
  mounted() {
    wxShare.commShare("team");
  },
  methods: {
    async getTeam() {
      const res = await this.axios.get(
        `team?limit=${this.limit}&page=${this.page}`
      );
      console.log(res);
      if (res.code === 200) {
        console.log(res);
        const { list, meta } = res.data;
        list.forEach((item) => {
          this.teamList.push(item);
        });
        this.loading = false;
        if (this.teamList.length >= meta.total) this.finished = true;
        console.log(this.teamList);
      }
    },
    onLoad() {
      this.page++;
      this.getTeam();
    },
    async isJoin(item) {
      const res = await this.axios.post(`team/join/${item.id}`);
      if (res.code === 200) {
        item.has_join === 0
          ? (this.$toast("加入成功"), (item.has_join = 1))
          : (this.$toast("退出成功"), (item.has_join = 0));
      }
    },
    toActivity(name) {
      console.log(name);
      this.$router.push({ path: `/activityList?name=${name}` });
    },
  },
};
</script>

<style lang="less" scoped>
.team {
  position: relative;
  padding-top: 30px;
  .bg-img {
    position: absolute;
    top: 0;
  }
  ul {
    position: relative;
    li {
      width: 92%;
      height: 164px;
      background: #fff;
      border-radius: 18px;
      margin: auto;
      padding-top: 29px;
      padding-left: 32px;
      position: relative;
      margin-bottom: 30px;
      .top {
        display: flex;
        align-items: center;
        img {
          width: 18px;
          height: 18px;
          margin-right: 17px;
        }
        .p1 {
          font-size: 28px;
        }
        margin-bottom: 10px;
      }
      .p2 {
        font-size: 24px;
        color: #6f6f6f;
        margin-left: 34px;
      }
      .btn-box {
        position: absolute;
        right: 20px;
        bottom: 20px;
        button {
          width: 130px;
          height: 44px;
          background: #f26a52;
          border-radius: 10px;
          color: #fff;
          font-size: 24px;
          margin-left: 20px;
        }
        .join {
          background: #dadada;
        }
      }
    }
  }
}
</style>